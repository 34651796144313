:root {
  --primary-gradient: linear-gradient(94deg, #83B0CB 2.41%, #3D5A80 33.48%, #293241 68.33%, #EE6C4D 111.17%);
  --link-gradient: linear-gradient(94deg, #98C1D9 2.41%, #3D5A80 33.48%, #293241 68.33%, #EE6C4D 111.17%);
  --gradient-number-1: linear-gradient(56deg, #98C1D9 -30.06%, #3D5A80 136.42%, #293241 323.22%, #EE6C4D 552.85%);
  --gradient-number-2: linear-gradient(56deg, #98C1D9 -181.92%, #3D5A80 -28.93%, #293241 142.72%, #EE6C4D 353.75%);
  --gradient-number-3: linear-gradient(55deg, #98C1D9 -346.7%, #3D5A80 -213.84%, #293241 -64.77%, #EE6C4D 118.49%);
  --gradient-number-4: linear-gradient(92deg, #98C1D9 15.43%, #EE6C4D 100.75%);
  --gradient-number-5: linear-gradient(92deg, #98C1D9 23.32%, #EE6C4D 69.85%);
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

.gradient1 {
  background: var(--primary-gradient);
}

.gradient2 {
  background: var(--link-gradient);
}

.gradient-border {
  background: linear-gradient(94deg, #83B0CB 2.41%, #3D5A80 33.48%, #293241 68.33%, #EE6C4D 111.17%);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-text-fill-color: transparent;
  border-radius: 9999px;
}

.start-gradient {
  background: var(--gradient-number-4);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 110%;
  background-position: 0 0;
  letter-spacing: -0.42px;
}

.finish-gradient {
  background: var(--gradient-number-5);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 110%;
  letter-spacing: -0.42px;
}

.gradient-text {
  background: var(--primary-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
}

.main-gradient2 {
  background: linear-gradient(94deg, #98C1D9 2.41%, #EE6C4D 111.17%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.number {
  font-family: Inter var;
  font-size: 90px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  letter-spacing: -4px;
}

.first {
  background: var(--gradient-number-1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.second {
  background: var(--gradient-number-2);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.third {
  background: var(--gradient-number-3);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

::placeholder {
  font-family: Inter var;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}