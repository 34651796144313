@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Default styles for small devices */
  .heading1 {
    background: var(--primary-gradient);
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    font-family: Manrope;
    font-size: 50px; /* mobile */
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.32px;
    padding-bottom: 0.2em;
    margin-bottom: -0.2em;
  }

  .heading2 {
    font-family: Manrope;
    font-size: 28px; /* mobile */
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.28px;
  }

  .heading3 {
    color: #000;
    font-family: Manrope;
    font-size: 24px; /* mobile */
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.2px;
  }

  .heading4 {
    color: #000;
    font-family: Manrope;
    font-size: 20px; /* mobile */
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.2px;
  }

  .supertitle {
    font-family: Inter var;
    font-size: 12px; /* mobile */
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }

  .text-big {
    color: rgba(0, 0, 0, 0.60);
    font-family: Inter var;
    font-size: 18px; /* mobile */
    font-weight: 400;
    line-height: normal;
  }

  .text-hero {
    color: rgba(0, 0, 0, 0.60);
    font-family: Inter var;
    font-size: 16px; /* mobile */
    font-weight: 400;
    line-height: normal;
  }

  .text-normal {
    font-family: Inter var;
    font-size: 16px; /* mobile */
    font-weight: 400;
    line-height: normal;
  }

  .text-small {
    color: rgba(0, 0, 0, 0.80);
    font-family: Inter var;
    font-size: 14px; /* mobile */
    font-weight: 400;
    line-height: normal;
  }

  .description {
    color: rgba(0, 0, 0, 0.80);
    font-family: Inter var;
    font-size: 14px; /* mobile */
    font-weight: 400;
    line-height: normal;
  }

  .medium-link {
    font-family: Inter var;
    font-size: 16px; /* mobile */
    font-weight: 500;
    line-height: normal;
    background: var(--primary-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .small-link {
    color: #000;
    font-family: Inter var;
    font-size: 14px; /* mobile */
    font-weight: 400;
    line-height: normal;
  }

  /* Media queries for md and lg devices */
  @media (min-width: 768px) {
    .heading1 {
      font-size: 56px; /* md and lg */
    }

    .heading2 {
      font-size: 42px; /* md and lg */
    }

    .heading3 {
      font-size: 24px; /* md and lg */
    }

    .heading4 {
      font-size: 20px; /* md and lg */
    }

    .supertitle {
      font-size: 12px; /* md and lg */
    }

    .text-big {
      font-size: 18px; /* md and lg */
    }

    .text-hero {
      font-size: 18px; /* md and lg */
    }

    .text-normal {
      font-size: 16px; /* md and lg */
    }

    .text-small {
      font-size: 14px; /* md and lg */
    }

    .description {
      font-size: 14px; /* md and lg */
    }

    .medium-link {
      font-size: 16px; /* md and lg */
    }

    .small-link {
      font-size: 14px; /* md and lg */
    }

  }
}
