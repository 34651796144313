::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #a9a9a9;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
  }