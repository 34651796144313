/* MANROPE font family */

@font-face {
    font-family: 'Manrope';
    font-weight: 200; /* Extra Light */
    font-display: swap;
    font-style: normal;
    src: url('./manrope/Manrope-ExtraLight.ttf') format('truetype');
}
  
@font-face {
    font-family: 'Manrope';
    font-weight: 300; /* Light */
    font-display: swap;
    font-style: normal;
    src: url('./manrope/Manrope-Light.ttf') format('truetype');
}
  
@font-face {
    font-family: 'Manrope';
    font-weight: 400; /* Regular */
    font-display: swap;
    font-style: normal;
    src: url('./manrope/Manrope-Regular.ttf') format('truetype');
}
  
@font-face {
    font-family: 'Manrope';
    font-weight: 500; /* Medium */
    font-display: swap;
    font-style: normal;
    src: url('./manrope/Manrope-Medium.ttf') format('truetype');
}
  
@font-face {
    font-family: 'Manrope';
    font-weight: 600; /* Semi Bold */
    font-display: swap;
    font-style: normal;
    src: url('./manrope/Manrope-SemiBold.ttf') format('truetype');
}
  
@font-face {
    font-family: 'Manrope';
    font-weight: 700; /* Bold */
    font-display: swap;
    font-style: normal;
    src: url('./manrope/Manrope-Bold.ttf') format('truetype');
}
  
@font-face {
    font-family: 'Manrope';
    font-weight: 800; /* Extra Bold */
    font-display: swap;
    font-style: normal;
    src: url('./manrope/Manrope-ExtraBold.ttf') format('truetype');
}

/* Inter font family */

@font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url('./inter/Inter-roman.var.woff2?v=3.18') format('woff2');
  }
  
@font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
    font-named-instance: 'Italic';
    src: url('./inter/Inter-italic.var.woff2?v=3.18') format('woff2');
  }
  
  