.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100% !important;
}

.react-datepicker {
  font-family: Inter var;
  background-color: #323A44 !important;
  border: none !important;
  border-radius: 20px !important;
  color: white !important;
}

.react-datepicker__header {
  background-color: #323A44 !important;
  border-radius: 20px !important;
  color: white !important;
  border: none !important;
}

.react-datepicker__current-month, .react-datepicker__day-name {
  color: white !important;
}

.react-datepicker__day--selected {
  background-color: #3b82f6 !important;
  color: blue !important;
}

.react-datepicker__day:hover {
  background-color: #3b82f6 !important;
  color: white;
}

.react-datepicker__day--disabled {
  color: #202834 !important;
  font-weight: 400 !important;
}

.react-datepicker__day--disabled:hover {
  background-color: #202834 !important;
  cursor: not-allowed;
}

.react-datepicker__day {
  font-size: 1rem !important;
  border-radius: 0.5rem;
  color: white !important;
}


.react-datepicker__dropdown {
  background-color: #1e3a8a !important;
  color: white !important;
}

.react-datepicker__today-button {
  background-color: #3b82f6 !important;
  color: white !important;
}

.react-datepicker__close {
  background-color: #dc2626 !important;
  color: white !important;
}
